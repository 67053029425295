/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx } from "theme-ui"
import { useState } from "react"
import Collapse from "@kunukn/react-collapse"
import { Link } from '@reach/router';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components//section"
import Wrapper from '../components/Wrapper';

const faqData = [
  {
    question: 'Are there any fees to send money using Kongrads?',
    answer: <>There are no fees for the donor to send money. An administration fee is deducted from the recipient for each transaction. See <Link to="/pricing">Pricing Page</Link>.</>
  },
  {
    question: 'How long does it take to receive money?',
    answer: 'Money Sent with Kongrads is typically available within 2-3 business days. If you have been sent a donation and it has been more than three days, we recommend confirming that you have fully connected your account with STRIPE, and that you entered the correct email address or U.S. mobile number.'
  },
  {
    question: 'Can I use Kongrads with family and friends Internationally?',
    answer: 'Kongrads is only available in the United States at this time.'
  },
]

const ItemIcon = ({ isOpened }) => (
  <div
    sx={{
      bg: "blueRibbon",
      borderRadius: "50%",
      size: "24px",
      color: "white",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
      ml: 3,
      ":before, :after": {
        backgroundColor: "currentColor",
        borderRadius: "1px",
        content: '""',
        height: "2px",
        left: "50%",
        mt: "-1px",
        ml: "-8px",
        position: "absolute",
        top: "50%",
        width: "16px",
      },
      ":after": {
        transform: "rotate(-90deg)",
        display: isOpened ? "none" : "block",
      },
    }}
  />
)

const FaqItem = ({ question, answer }) => {
  const [isOpened, setOpened] = useState(false)
  const toggleOpened = () => setOpened(!isOpened)
  return (
    <div
      sx={{
        "& + &": {
          mt: 4,
        },
      }}
    >
      <button
        onClick={toggleOpened}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          color: "charade",
          fontSize: 2,
          fontWeight: "bold",
          width: "100%",
          border: "none",
          p: ["18px 15px"],
          boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
          bg: "white",
          borderRadius: "3px",
          cursor: "pointer",
          lineHeight: 1.5,
          textAlign: "left",
          ":focus": {
            outline: "none",
          },
        }}
      >
        <div>{question}</div>
        <ItemIcon isOpened={isOpened} />
      </button>
      <Collapse
        isOpen={isOpened}
        sx={{ transition: "height 280ms cubic-bezier(0.4, 0, 0.2, 1)" }}
      >
        <div
          sx={{
            mt: "10px",
            boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
            bg: "white",
            borderRadius: "3px",
            p: ["18px 15px"],
          }}
        >
          {answer}
        </div>
      </Collapse>
    </div>
  )
}

const FaqPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <Section
      title="Frequently Asked Questions"
      // subtitle="Phasellus luctus laoreet arcu, vel porta metus imperdiet sit amet."
    >
      <Wrapper>
        <div sx={{ mb: 5, textAlign: "left", variant: "centeredInner" }}>
          {faqData.map((item, index) => (
            <FaqItem
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </Wrapper>
    </Section>
  </Layout>
)

export default FaqPage
